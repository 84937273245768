<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-04">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header">사회공헌활동</h1>
        <div class="feature-body">
          <p>지역을 넘어서, 한계를 넘어서, 모두가 건강한 사회를 만들어갑니다.</p>
        </div>
      </div>
    </div>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb"><a href="#">BMS 소식</a></li>
            <li class="breadcrumb">사회공헌활동</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

      <card-and-list
          :hasHeader="false"
          @getMoreList="getMoreList"/>
    </div>
  </main>
</template>

<script>
import CardAndList from "@/components/common/CardAndList";
import {mapActions} from "vuex";

export default {
  name: "SocialContribute",
  components: {
    CardAndList,
  },
  async mounted() {
    await this.initBoardList({
      query: {
        sort: 'displayPostedDate,desc'
      },
      type: 'social-contribute'
    })
  },
  methods: {
    ...mapActions(["fetchBoardList", "initBoardList"]),
    async getMoreList() {
      await this.fetchBoardList({
        query: {
          page: this.$store.state.board.page + 1,
          sort: 'displayPostedDate,desc'
        }, type: 'social-contribute'
      })
    }
  },
}
</script>

<style scoped>

</style>
